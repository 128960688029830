<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Error :errors="errors"/>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <Filter @submit="getDataTable()" ref="filter"/>
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>

                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>

                    <Column field="region_name" header="Region">
                        <template #body="slotProps">
                            {{slotProps.data.region_name}}
                        </template>
                    </Column>
                    <Column field="dist_code" header="Distributor Kode">
                        <template #body="slotProps">
                            {{slotProps.data.dist_code}}
                        </template>
                    </Column>
                    <Column field="dist_name" header="Distributor Nama">
                        <template #body="slotProps">
                            {{slotProps.data.dist_name}}
                        </template>
                    </Column>
                    <Column field="wilayah_name" header="Wilayah">
                        <template #body="slotProps">
                            {{slotProps.data.wilayah_name}}
                        </template>
                    </Column>
                    <Column field="customer_code" header="Outlet Kode">
                        <template #body="slotProps">
                            {{slotProps.data.customer_code}}
                        </template>
                    </Column>
                    <Column field="customer_name" header="Outlet Nama">
                        <template #body="slotProps">
                            {{slotProps.data.customer_name}}
                        </template>
                    </Column>
                    <Column field="channel_distribusi" header="Channel Distribusi">
                        <template #body="slotProps">
                            {{slotProps.data.channel_distribusi}}
                        </template>
                    </Column>
                    <Column field="klasifikasi_id" header="Kode Outlet Klasifikasi">
                        <template #body="slotProps">
                            {{slotProps.data.klasifikasi_id}}
                        </template>
                    </Column>
                    <Column field="klasifikasi_name" header="Nama Outlet Klasifikasi">
                        <template #body="slotProps">
                            {{slotProps.data.klasifikasi_name}}
                        </template>
                    </Column>
                    <Column field="username" header="Username">
                        <template #body="slotProps">
                            {{slotProps.data.username}}
                        </template>
                    </Column>
                    <Column field="nama" header="Nama">
                        <template #body="slotProps">
                            {{slotProps.data.nama}}
                        </template>
                    </Column>
                    <Column field="visit_in_type" header="Tipe Visit">
                        <template #body="slotProps">
                            {{slotProps.data.visit_in_type}}
                        </template>
                    </Column>
                    <Column field="visit_in_time" header="Checkin Waktu">
                        <template #body="slotProps">
                            {{slotProps.data.visit_in_time}}
                        </template>
                    </Column>
                    <Column field="visit_in_coordinate" header="Checkin Koordinat">
                        <template #body="slotProps">
                            {{slotProps.data.visit_in_coordinate}}
                        </template>
                    </Column>
                    <Column field="visit_in_gps_address" header="Checkin Alamat GPS">
                        <template #body="slotProps">
                            {{slotProps.data.visit_in_gps_address}}
                        </template>
                    </Column>
                    <Column field="visit_in_url_foto" header="Checkin Photo">
                        <template #body="slotProps">
                            {{slotProps.data.visit_in_url_foto}}
                        </template>
                    </Column>
                    <Column field="visit_out_time" header="Checkout Waktu">
                        <template #body="slotProps">
                            {{slotProps.data.visit_out_time}}
                        </template>
                    </Column>
                    <Column field="visit_out_coordinate" header="Checkout Koordinat">
                        <template #body="slotProps">
                            {{slotProps.data.visit_out_coordinate}}
                        </template>
                    </Column>
                    <Column field="visit_out_gps_address" header="Checkout Alamat GPS">
                        <template #body="slotProps">
                            {{slotProps.data.visit_out_gps_address}}
                        </template>
                    </Column>
                    <Column field="visit_out_not_order" header="Checkout Not Order?">
                        <template #body="slotProps">
                            {{slotProps.data.visit_out_not_order}}
                        </template>
                    </Column>
                    <Column field="visit_out_note" header="Checkout Note">
                        <template #body="slotProps">
                            {{slotProps.data.visit_out_note}}
                        </template>
                    </Column>
                    <Column field="is_by_phone" header="By Phone?">
                        <template #body="slotProps">
                            {{slotProps.data.is_by_phone}}
                        </template>
                    </Column>
                    <Column field="by_phone_nohp" header="No HP">
                        <template #body="slotProps">
                            {{slotProps.data.by_phone_nohp}}
                        </template>
                    </Column>
                    <Column field="by_phone_note" header="By Phone Note">
                        <template #body="slotProps">
                            {{slotProps.data.by_phone_note}}
                        </template>
                    </Column>
                    <Column field="is_tunda_order" header="Tunda Order?">
                        <template #body="slotProps">
                            {{slotProps.data.is_tunda_order}}
                        </template>
                    </Column>
                    <Column field="tunda_start_time" header="Tunda Start">
                        <template #body="slotProps">
                            {{slotProps.data.tunda_start_time}}
                        </template>
                    </Column>
                    <Column field="tunda_end_time" header="Tunda End">
                        <template #body="slotProps">
                            {{slotProps.data.tunda_end_time}}
                        </template>
                    </Column>
                    <Column field="durasi" header="Durasi">
                        <template #body="slotProps">
                            {{slotProps.data.durasi}}
                        </template>
                    </Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Filter from '../../components/data/visit/Filter.vue';
import Error from '../../components/Error.vue';

export default {
    components: {
		'Filter': Filter,
		'Error': Error,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors']),
		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/visit',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "region_id" : this.$refs.filter.filters.region_id,
                    "dist_code" : this.$refs.filter.filters.dist_code,
                    "start_date" : this.$refs.filter.period_start_label,
                    "end_date" : this.$refs.filter.period_end_label,
                }
            })
            .then(res => {
                this.data = res.data.data.data;
                this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                this.totalItemsCount = res.data.data.total;
                this.rows = parseInt(res.data.data.per_page);
                this.loading=false;
                this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/visit/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "region_id" : this.$refs.filter.filters.region_id,
                "dist_code" : this.$refs.filter.filters.dist_code,
                "start_date" : this.$refs.filter.period_start_label,
                "end_date" : this.$refs.filter.period_end_label,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Visit Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>