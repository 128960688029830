<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Periode</label>
                    <Calendar selectionMode="range"  v-tooltip.top="'Maksimal 30 hari'" :manualInput="false" v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="dropdownRegion">Region</label>
                    <Dropdown v-model="filters.region_id" :loading="loadingDropdownRegion" :options="dataDropdownRegion" optionLabel="region_name" optionValue="region_id" placeholder="Pilih Region" :filter="true" :showClear="true" @filter="searchDropdownRegion($event, 'filter')" @change="changeDist()"/>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="dropdownDistributor">Distributor</label>
                    <Dropdown v-model="filters.dist_code" :loading="loadingDropdownDistributor" :options="dataDropdownDistributor" optionLabel="label" optionValue="dist_code" placeholder="Pilih Distributor" :filter="true" :showClear="true" @filter="searchDropdownDistributor($event, 'filter')"/>
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownRegion: false,
            loadingDropdownDistributor: false,

            // dataDropdown
            dataDropdownRegion: null,
            dataDropdownDistributor: null,

            // filter
            filters: {
                region_id: null,
                dist_code: null,
            },
            period: [new Date(), new Date()],
            maxDate: null,
        }
    },
    mounted() {
        this.searchDropdownRegion('');
    },
    created(){
    },
    watch: {
        period(){
            //max range 30 days
            this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 30);
        }
    },
    computed:{
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        // DROPDOWN
        searchDropdownRegion(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.drgn.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownRegion = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-region-v2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownRegion = res.data.data;
                            this.loadingDropdownRegion = false;
                        } else if (purpose == null) {
                            this.dataDropdownRegion = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeDist() {
            this.filters.dist_code = null;
            this.dataDropdownDistributor = null;
            this.searchDropdownDistributor('');
        },
        searchDropdownDistributor(event, purpose, valueEdit){
            setTimeout(() => {

                if (this.filters.region_id) {
                    if(valueEdit){
                        this.$refs.ddistributor.filterValue = valueEdit;
                    }

                    if(purpose == "filter"){
                        this.loadingDropdownDistributor = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor',
                        params: {
                            "search" : valueEdit ? valueEdit : event.value,
                            "region_id" : this.filters.region_id,
                        }
                    })
                    .then(res => {

                        if(purpose == "filter"){
                            this.dataDropdownDistributor = res.data.data;
                            this.loadingDropdownDistributor = false;
                        }else if(purpose == null){
                            this.dataDropdownDistributor = res.data.data;
                        }
                    })
                    .catch((err) => {
                    console.log(err);
                });
                }
            }, 250);
        },
        getDataTable(){
            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  
                this.$emit('submit');
            }else{
                 alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        }
    }
}
</script>

